footer{
    position:relative;
    bottom:0;
    width: 100%;
    color: black;
    background-color: white;
    font-size: 0.7rem;
    text-align: center;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

