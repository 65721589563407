@import url(https://fonts.googleapis.com/css?family=Playpen+Sans);
@import url(https://fonts.googleapis.com/css?family=Concert+One:regular);
@import url(https://fonts.googleapis.com/css?family=Rubik);

body {
  margin: 0;
  font-family: "Concert One", "Playpen Sans","Rubik";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow:scroll;
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

