.App {
  font-family: "Concert One", "Playpen Sans","Rubik";
  color: black;
  overflow: scroll;
}

*{
  box-sizing: border-box;
}

body{
  overflow:scroll;

}



